import React, { useState } from 'react';
import XSText from '../../ui/typography/XSText';
import TermAndConditionModal from '../../termAndCondition/TermAndConditionModal';
import PrivacyAndPolicyModal from '../../privacyAndPolicy/PrivacyAndPolicy';
export default function TermCondition() {
  const [openTermModal, setOpenTermModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);
  return (
    <>
      <div className="flex flex-row items-center mt-6">
        <XSText
          className="text-center"
          title={
            <div className="whitespace-pre-line">
              By purchasing the package you agree to our{'\n'}
              <a onClick={() => setOpenTermModal(true)} className="text-bold text-coral-500 cursor-pointer">
                Terms and Conditions,{' '}
              </a>
              and{' '}
              <a onClick={() => setOpenPrivacyModal(true)} className=" text-bold text-coral-500 cursor-pointer">
                Privacy Policy.
              </a>
            </div>
          }
        />
      </div>
      <TermAndConditionModal openModal={openTermModal} closeDynamicModal={() => setOpenTermModal(false)} />
      <PrivacyAndPolicyModal openPrivacyModal={openPrivacyModal} closeDynamicModal={() => setOpenPrivacyModal(false)} />
    </>
  );
}
