import React from 'react';
import DynamicModalScroll from '../ui/modal/DynamicModalInsideScroll';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { graphql, StaticQuery } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { useSelector } from 'react-redux';
import { REGISTERED_COUNTRY } from '../../constants/enum';

function TermAndConditionModalContent({ openModal, closeDynamicModal, data }) {
  let page_content = data?.prismicNewTermsAndConditions?.data?.body?.[0]?.primary?.page_content || [];
  let page_content2 = data?.prismicNewTermsAndConditions?.data?.body?.[0]?.primary?.page_content2 || [];
  const registrationCountry = useSelector(
    (state) => state?.newAccountSetup?.newCompanyRegistrationData?.registrationCountry
  );

  // Removing the first element from the content arrays as it represents the heading:
  // "Terms & Conditions for Company Secretary Services"
  page_content?.length && page_content?.shift();
  page_content2?.length && page_content2?.shift();

  return (
    <DynamicModalScroll
      openDynamicModal={openModal}
      closeDynamicModal={closeDynamicModal}
      scrollType="paper"
      minWidth="520px"
      action={
        <>
          <div className="mb-3">
            <PrimaryButton
              id="term-and-conditions-close-btn"
              className="modal-cancel-button font-weight-bold border border-gray-400"
              bgColor="bg-coral-500"
              caption="Close"
              onClick={closeDynamicModal}
            />
          </div>
        </>
      }
      title={
        <>
          <MDSubtitleText
            className="mb-4"
            title={
              <span className="flex justify-between items-center">
                <span>Terms and Conditions</span>
                <span>
                  <IconButton onClick={closeDynamicModal}>
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            }
            fontWeight="text-bold"
          />
        </>
      }
      children={
        <PrismicRichText
          field={registrationCountry === REGISTERED_COUNTRY?.HONGKONG ? page_content : page_content2}
          components={{
            heading2: ({ children }) => (
              <>
                <br />
                <h2 className="text-bold text-xl text-gray-300 text-left no-link letter-spacing-p leading-5">
                  {children}
                </h2>
                <br />
              </>
            )
          }}
        />
      }
    />
  );
}
export default function TermAndConditionModal({ openModal, closeDynamicModal }) {
  const query = graphql`
    query termsAndConditionsQuery {
      prismicNewTermsAndConditions(uid: { eq: "company-secretary-services" }) {
        uid
        data {
          title {
            text
          }
          body {
            primary {
              page_content {
                text
                spans {
                  end
                  start
                  type
                }
                type
              }
              page_content2 {
                spans {
                  data {
                    link_type
                    url
                  }
                  start
                  end
                  type
                }
                text
                type
              }
            }
            slice_type
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        return (
          <>
            <TermAndConditionModalContent openModal={openModal} closeDynamicModal={closeDynamicModal} data={data} />
          </>
        );
      }}
    />
  );
}
