import React from 'react';

const Star = ({ filled }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill={filled ? '#FFC730' : 'none'} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7032 6.28921C15.632 6.09788 15.5041 5.93283 15.3366 5.81609C15.1691 5.69935 14.97 5.63648 14.7658 5.63588H10.7078C10.6388 5.63593 10.5714 5.61453 10.5151 5.57464C10.4587 5.53475 10.4161 5.47835 10.3932 5.41321L8.93651 1.28454C8.86516 1.09372 8.73722 0.929237 8.56983 0.813114C8.40244 0.696991 8.20357 0.634766 7.99984 0.634766C7.79611 0.634766 7.59724 0.696991 7.42985 0.813114C7.26246 0.929237 7.13452 1.09372 7.06317 1.28454L7.05984 1.29521L5.60651 5.41321C5.58362 5.47824 5.54113 5.53457 5.4849 5.57445C5.42866 5.61433 5.36145 5.63579 5.29251 5.63588H1.23317C1.02772 5.63572 0.827203 5.69885 0.658896 5.81669C0.49059 5.93452 0.362663 6.10134 0.292515 6.29445C0.222366 6.48755 0.213401 6.69758 0.266838 6.89597C0.320275 7.09435 0.433521 7.27146 0.591174 7.40321L4.04717 10.2699C4.09887 10.3128 4.1364 10.3703 4.15484 10.4349C4.17328 10.4995 4.17177 10.5682 4.15051 10.6319L2.69851 14.9859C2.6314 15.1872 2.63008 15.4046 2.69472 15.6067C2.75937 15.8089 2.88663 15.9852 3.0581 16.1102C3.22958 16.2352 3.43637 16.3024 3.64857 16.3021C3.86077 16.3018 4.06738 16.234 4.23851 16.1085L7.80251 13.4952C7.85966 13.4533 7.92866 13.4308 7.99951 13.4308C8.07035 13.4308 8.13936 13.4533 8.19651 13.4952L11.7592 16.1079C11.9302 16.2338 12.1369 16.302 12.3493 16.3025C12.5617 16.3031 12.7688 16.236 12.9405 16.111C13.1122 15.986 13.2397 15.8096 13.3044 15.6073C13.3691 15.405 13.3678 15.1873 13.3005 14.9859L11.8485 10.6292C11.8272 10.5655 11.8257 10.4968 11.8442 10.4322C11.8626 10.3676 11.9001 10.3101 11.9518 10.2672L15.4145 7.39654C15.5701 7.26368 15.6813 7.08626 15.7329 6.88825C15.7845 6.69023 15.7741 6.48115 15.7032 6.28921Z"
      fill="#FFC730"
    />
  </svg>
);

const FiveStar = () => {
  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {[...Array(5)].map((_, index) => (
        <Star key={index} filled={true} />
      ))}
    </div>
  );
};

export default FiveStar;
