import React, { useState, useEffect } from 'react';
import SEO from '../../components/seo';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import '../../styles/pages/new-company-signup/start.scss';
import Navbar from '../../components/new-company-signup/components/Navbar';
import {
  SIGN_UP_FLOW_CONSTANT,
  COMPANY_REGISTRATION_COUNTRY,
  COMPANY_CREATE_STEPS,
  STATRYS_TRUST_PILOT_URL
} from '../../constants/enum';
import { ENDPOINTS } from '../../service/end-points';
import CompanyOwners from '../../components/new-company-signup/CompanyOwners';
import H4HeaderText from '../../components/ui/typography/H4HeaderText';
import XXSText from '../../components/ui/typography/XXSText';
import Industry from '../../components/new-company-signup/Industry';
import Accounting from '../../components/new-company-signup/Accounting';
import PersonalInformation from '../../components/new-company-signup/PersonalInformation';
import PaymentUI from '../../components/new-company-signup/checkout/PaymentUI';
import SatisfactionGuaranteed from '../../components/new-company-signup/components/SatisfactionGuaranteed';
import IndustryWarningBox from '../../components/new-company-signup/components/IndustryWarningBox';
import XSText from '../../components/ui/typography/XSText';
import MSText from '../../components/ui/typography/MSText';
import Location from '../../images/icons/location.svg';
import TrustPilotLogo from '../../images/icons/trustpilot-logo.svg';
import TrustPilotStars from '../../images/icons/trustpilot-stars.svg';
import IconComma from '../../images/icons/inverted-comma.svg';
import CheckListIcon from '../../images/icons/checklist.svg';
import ProfessionalManDoctorIcon from '../../images/icons/inline/professional-man-doctor.inline.svg';
import CursorChooseIcon from '../../images/icons/inline/cursor-choose.inline.svg';
import Review from '../../components/new-company-signup/Review';
import GreenTick from '../../images/icons/green-tick.svg';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from '../../components/new-company-signup/redux/action';
import RegistrationCountry from '../../components/new-company-signup/companyDetails/RegistrationCountry';
import NavigationBar from '../../components/sideBar/NavigationBar';
import { isLoggedIn } from '../../components/authService/auth';
import ParentLoader from '../../components/ui/loaders/ParentLoader';
import Banner from '../../components/common/Banner';
import FiveStar from '../../images/icons/customSvg/FiveStar';
const hongKongservicePoints = [
  {
    heading: `All-in-One Company Formation Package`,
    points: [
      <p className="whitespace-pre-line">
        <span className="text-bold"> Incorporation Services</span>
        {`\n`} (Filing, Registration, Certificate).
      </p>,
      <p className="text-bold">1 Year Company Secretary Services</p>,
      <p className="text-bold">1 Year Registered Address in HK</p>,
      <p className="text-bold">Business Account Opening Assistance</p>
    ]
  }
];
const singaporeServicePoints = [
  {
    heading: `All-in-One Company Formation Package`,
    points: [
      <p className="whitespace-pre-line">
        <span className="text-bold">Incorporation Services</span>
        {`\n`} (Filing, Registration, Certificate).
      </p>,
      <p className="text-bold">Nominee Director</p>,
      <p className="text-bold">Company Secretary Services</p>,
      <p className="text-bold">Registered Address in Singapore</p>
    ]
  }
];
function Start({ location, newCompanyAllInfo, newCompanyData, resetNewCompanyRegistration }) {
  const { state } = location;
  const query = new URLSearchParams(location?.search);
  const registrationCountryParam = query.get('country');
  const [payload, setPayload] = useState({
    step: registrationCountryParam ? 1 : 0
  });
  const [allFieldsData, setAllFieldsData] = useState({
    companyName: ''
  });
  let initialStateOfCompliation = {
    companyInformationCompleted: false,
    ownerInformationCompleted: false,
    paymentCompleted: false
  };
  const [completedSteps, setCompletedSteps] = useState(initialStateOfCompliation);
  const servicePoints =
    newCompanyAllInfo?.registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
      ? hongKongservicePoints
      : singaporeServicePoints;
  const { step } = payload;
  let nextStep = () => {
    setPayload((prev) => ({
      ...prev,
      step: step + 1
    }));
  };
  let prevStep = () => {
    state?.fromCompanyCreationProductPage
      ? navigate('/hong-kong-company-registration')
      : state?.path === SIGN_UP_FLOW_CONSTANT?.GET_STARTED
      ? navigate(ENDPOINTS?.OPEN_ACCOUNT)
      : setPayload((prev) => ({
          ...prev,
          step: step - 1 > -1 ? step - 1 : 0
        }));
  };
  const params = new URLSearchParams(location?.search);
  const intentId = params.get('payment_intent');
  useEffect(() => {
    const companyDataFromSession = JSON.parse(sessionStorage.getItem('newCompanyAllInfo'));
    newCompanyData(companyDataFromSession);
    if (intentId) {
      setPayload({ ...payload, step: 5 });
      setCompletedSteps({ companyInformationCompleted: true, ownerInformationCompleted: true, paymentCompleted: true });
    }
    return () => {
      resetNewCompanyRegistration();
    };
  }, []);
  const stepHandler = async () => {
    window.onpopstate = () => {
      prevStep();
    };
    if (registrationCountryParam) {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        registrationCountry: registrationCountryParam
      };
      await newCompanyData(newCompanyPayload);
    }
    if (step === 0 && !intentId) {
      return navigate('?=company-registration-country', {
        state: {
          fromCompanyCreationProductPage: state?.fromCompanyCreationProductPage
        }
      });
    }
    if (step === 1) {
      return navigate('?=owners-directors');
    }
    if (step === 2) {
      return navigate('?=industry');
    }
    if (step === COMPANY_CREATE_STEPS?.ACCOUNTING) {
      return navigate('?=accounting');
    }
    if (step === 4) {
      return navigate('?=personal-information');
    }
    if (step === 5 && !intentId) {
      return navigate('?step=checkout');
    }
  };

  useEffect(() => {
    stepHandler();
  }, [step]);

  let selectedCase = () => {
    switch (payload.step) {
      default:
        return <h1>coming soon...</h1>;
      case 0:
        return (
          <RegistrationCountry
            nextStep={nextStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setAllFieldsData={setAllFieldsData}
            allFieldsData={allFieldsData}
          />
        );
      case 1:
        return (
          <CompanyOwners
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
          />
        );
      case 2:
        return (
          <Industry
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
          />
        );
      case COMPANY_CREATE_STEPS?.ACCOUNTING:
        return (
          <Accounting
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setPayload={setPayload}
          />
        );
      case 4:
        return (
          <PersonalInformation
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setPayload={setPayload}
          />
        );
      case 5:
        return (
          <PaymentUI
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            nextStep={nextStep}
            registrationCountry={newCompanyAllInfo?.registrationCountry}
          />
        );
    }
  };

  let selectedInfo = () => {
    switch (payload.step) {
      default:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`Your company name must be unique. Avoid using any trademark names, or names that are too similar to existing businesses. That may cause the rejection of your application.
No matter the name you pick, it will end with the word "Limited" as per Hong Kong regulations.
If you are not sure yet about your company name you can skip this step and decide later.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );

      case 0:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <img src={IconComma} alt="IconComma" className="mb-4" />
            <XXSText title="I saved so much time and money [...] because they know the essentials and offered a package with secretary services and address. This is a must and you anyhow will need to outsource if it is a shell company." />
            <div className="mt-6 flex flex-row gap gap-2">
              <div className={` bg-blue-200 rounded-full name-container flex items-center justify-center`}>
                <XSText title="PA" className="p-2" textColor="text-gray-500" fontWeight="text-bold" />
              </div>
              <div className="flex flex-col gap gap-1">
                <MSText title="Pak Alexsander" />
                <div className="flex flex-row gap gap-2">
                  <img src={Location} alt="location" width="14" height="14" />
                  <MSText title="BL" textColor="text-gray-450" />
                </div>
              </div>
            </div>
            <a href="https://www.trustpilot.com/review/statrys.com" target="_blank" rel="noopener noreferrer">
              <div className="flex flex-wrap gap-4 md:mt-6 mt-3 items-center">
                <img src={TrustPilotLogo} width="91" height="23" alt="trustpilot logo" loading="eager" />
                <div className="vl"></div>
                <div className="mt-1 text-bold text-base">Rated Excellent</div>
                <img src={TrustPilotStars} alt="trust pilot stars" width="90" height="23" loading="eager" />
              </div>
            </a>
            <SatisfactionGuaranteed />
          </div>
        );
      case 1:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`Your company name must be unique. Avoid using any trademark names, or names that are too similar to existing businesses. That may cause the rejection of your application.\n\n${
                newCompanyAllInfo.registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG
                  ? 'No matter the name you pick, it will end with the word "Limited" as per Hong Kong regulations.'
                  : 'No matter the name you pick, it will end with the word “Pte. Ltd”.'
              }\n\nIf you are not sure yet about your company name you can skip this step and decide \nlater.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );
      case 2:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`A company owner is a person who owns shares in the company.\n\nA company owner may also be called a shareholder, member, or partner. These are legal terms to qualify a company owner.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );
      case 3:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`Pick up in our list the type of business that best matches with the future activity of your company. If no option fits, select “Others” and provide a brief description.`}
            />
            <IndustryWarningBox />
          </div>
        );
      case 4:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText
              fontWeight="text-regular"
              title={
                <span>
                  We help you <span className="text-bold">save</span> money with your accounting.
                  <br />
                  See how it works:
                </span>
              }
            />
            <div className="mt-5 flex gap-4 items-start">
              <div className="w-6">
                <ProfessionalManDoctorIcon />
              </div>
              <div>
                <XSText fontWeight="text-bold" title="Get expert accounting support" />
                <XSText
                  className="mt-2"
                  title="We have built a network of selected accounting firms to ensure the highest quality of services for our clients."
                />
              </div>
            </div>
            <div className="mt-5 flex gap-4 items-start">
              <img src={CheckListIcon} alt="CheckListIcon" />
              <div>
                <XSText fontWeight="text-bold" title="Receive multiple price quotes" />
                <XSText
                  className="mt-2"
                  title="If you ask our assistance for accounting, we will on an anonymous basis solicit price quotations from the accounting firms present in our network."
                />
              </div>
            </div>
            <div className="mt-5 flex gap-4 items-start">
              <div className="w-6">
                <CursorChooseIcon />
              </div>
              <div>
                <XSText fontWeight="text-bold" title="Choose the best option for your needs" />
                <XSText
                  className="mt-2"
                  title="We will present you with the three most competitive quotes, allowing you to make an informed decision."
                />
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-bold text-[28px] whitespace-pre-line" title={`What’s included?`} />
            {servicePoints?.map((service, index) => {
              return (
                <div key={index}>
                  <XSText fontWeight="text-bold" className="mt-4" title={service?.heading} />
                  {service?.points.map((point, pointIndex) => {
                    return (
                      <div className="flex flex-row items-start mt-3" key={pointIndex}>
                        <img src={GreenTick} alt="check" width="16" height="16" />
                        <XSText title={point} className="ml-1 whitespace-pre-line" />
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <SatisfactionGuaranteed />
            <div className="company-registration-info-container mt-6">
              <img src={IconComma} alt="IconComma" className="mb-4" />
              <XXSText title="I saved so much time and money [...] because they know the essentials and offered a package with secretary services and address. This is a must and you anyhow will need to outsource if it is a shell company." />
              <div className="flex flex-col gap-4 mt-4">
                <FiveStar />
                <div className="flex flex-row gap gap-2">
                  <div className={` bg-blue-200 rounded-full name-container flex items-center justify-center`}>
                    <XSText title="PA" className="p-2" textColor="text-gray-500" fontWeight="text-bold" />
                  </div>
                  <div className="flex flex-col gap gap-1">
                    <span className={'text-bold text-sm'}> Pak Alexsander </span>
                    <div className="flex flex-row gap gap-2">
                      <img src={Location} alt="location" width="14" height="14" />
                      <MSText title="BL" textColor="text-gray-450" />
                    </div>
                  </div>
                </div>
                <a href={STATRYS_TRUST_PILOT_URL} target="_blank" rel="noopener noreferrer">
                  <div className="flex flex-wrap gap-4 items-center">
                    <img src={TrustPilotLogo} width="91" height="23" alt="trustpilot logo" loading="eager" />
                    <div className="vl"></div>
                    <div className="mt-1 text-bold text-base">Rated Excellent</div>
                    <img src={TrustPilotStars} alt="trust pilot stars" width="90" height="23" loading="eager" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        );
      case 6:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <img src={IconComma} alt="IconComma" className="mb-4" />
            <XXSText title="I saved so much time and money [...] because they know the essentials and offered a package with secretary services and address. This is a must and you anyhow will need to outsource if it is a shell company." />
            <div className="mt-6 flex flex-row gap gap-2">
              <div className={` bg-blue-200 rounded-full name-container flex items-center justify-center`}>
                <XSText title="PA" className="p-2" textColor="text-gray-500" fontWeight="text-bold" />
              </div>
              <div className="flex flex-col gap gap-1">
                <MSText title="Pak Alexsander" />
                <div className="flex flex-row gap gap-2">
                  <img src={Location} alt="location" width="14" height="14" />
                  <MSText title="BL" textColor="text-gray-450" />
                </div>
              </div>
            </div>
            <a href="https://www.trustpilot.com/review/statrys.com" target="_blank" rel="noopener noreferrer">
              <div className="flex flex-wrap gap-4 md:mt-6 mt-3 items-center">
                <img src={TrustPilotLogo} width="91" height="23" alt="trustpilot logo" loading="eager" />
                <div className="vl"></div>
                <div className="mt-1 text-bold text-base">Rated Excellent</div>
                <img src={TrustPilotStars} alt="trust pilot stars" width="90" height="23" loading="eager" />
              </div>
            </a>
            <SatisfactionGuaranteed />
          </div>
        );
    }
  };
  const MainChildren = () => {
    return (
      <div className="md:pl-5 md:px-6 px-4 md:pt-0 pt-6 md:pb-0 pb-20  md:mt-0 flex flex-col md:flex-row md:gap-4 gap-16 items-center justify-center self-center h-full mx-auto">
        {selectedCase()}
        {selectedInfo()}
      </div>
    );
  };

  return (
    <div className="bg-customBg-secondaryBg md:h-screen min-h-screen w-screen relative md:overflow-hidden">
      <ParentLoader />
      <div className="company-signup-blur"></div>
      <SEO keywords={[`Statrys`]} title={'Registration'} description={'Registration of personal info'} />
      {isLoggedIn() ? (
        <NavigationBar hideBanner={true} noSideBar={true} children={<MainChildren />} />
      ) : (
        <>
          <Navbar step={step} />
          <MainChildren />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData)),
    resetNewCompanyRegistration: () => dispatch(actionsForRegistrationNewCompany.resetnewCompanyRegistration())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Start);
Start.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any,
  newCompanyAllInfo: PropTypes.object,
  newCompanyData: PropTypes.func
};
