import React, { useState } from 'react';
import XSText from '../../ui/typography/XSText';
import MoneyBackGuarantee from '../../common/MoneyBackGuarantee';

export default function SatisfactionGuaranteed({ className }) {
  const [openMoneyBackModal, setOpenMoneyBackModal] = useState(false);

  return (
    <div className={`bg-yellow-300 rounded-lg px-6 py-4 md:mt-8 mt-4 relative z-10 ${className}`}>
      <XSText className="mb-2" fontWeight="text-bold" title="❤️ 100% Risk-Free Guarantee" />
      <XSText
        title={
          <span>
            Try it for 30 days. Love it or your money <br />
            back—no questions asked!{' '}
            <span onClick={() => setOpenMoneyBackModal(true)} className="cursor-pointer text-bold">
              Learn more
            </span>
          </span>
        }
      />
      <MoneyBackGuarantee
        openMoneyBackModal={openMoneyBackModal}
        closeMoneyBackModal={() => setOpenMoneyBackModal(false)}
      />
    </div>
  );
}
